.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px;
}

.loginTitle {
    letter-spacing: 0.7rem;
    margin-bottom: 20px;
}

.loginForm {
    border: 1px solid brown;
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.loginInput {
    border: none;
    border-bottom: 1px solid aqua;
    padding: 5px 10px;
    outline: none;
    font-size: 24px;
}

.loginButtonContainer {
    margin: 20px 0 10px 0;
}

.loginSkip {
    margin-right: 10px;
}

@media only screen and (max-width: 768px) {
    .loginTitle {
        letter-spacing: 0.5rem;
        text-align: center;
    }

    .loginForm {
        border:none
    } 
}
