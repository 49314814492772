* {
    margin: 0;
    padding: 0;
}

body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}

.btn {
    width: 200px;
    background-color: transparent;
    border-color: aqua;
    font-size: 24px;
    padding: 0 10px;
    border-radius: 10px;
}

.btn:hover {
    background-color: aqua;
    color: white;
    transform: scale(1.05);
    transition-duration: 1000ms;
}


/* TaskList */ 

.taskListContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 20px 0;
    font-size: 24px;
}

.taskContainer {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}


.taskCancel:hover {
    transform: scale(1.1);
    transition-duration: 600ms;
    color: aqua;
    cursor: pointer;
}

/* InputArea */ 

.InputAreaContainer {
    font-size: 24px;
    text-align: center;
}

.InputTitle {
    letter-spacing: 0.5rem;
    margin-bottom: 20px;
}

.InputArea {
    width: 450px;
    border: none;
    padding: 5px 3px;
    outline: none;
    font-size: 24px;
}

.InputAreaButton {
    font-size: 24px;
    width: 70px;
    padding: 5px 0;
    border: none;
    background-color: transparent;
}

.InputAreaButton:hover {
    background-color: aqua;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0 ;
    transition-duration: 1000ms;
}

.error {
    border-bottom: 1px solid red;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.InputForm {
    border-bottom: 1px solid aqua;
    display: flex;
    justify-content: space-between;
}

.InputError {
    color: red;
    letter-spacing: 0.1rem;
}

/* TaskChange */

.changeContainer {
    margin: -13px 0 0 20px;
    position: absolute;
}

.changeClose {
    position: absolute;
    opacity: 0.8;
    font-size: 24px;
    margin: -46px 0 0 -6px;
    cursor: pointer;
    color: red;
}

.changeClose:hover {
    transform: scale(1.3);
}
.chaneInput { 
    padding: 3px 10px;
    font-size: 18px;
    resize: none;
    text-transform: uppercase;
}

.changeButton {
    background-color: aqua;
    border-color: aqua;
    font-size: 18px;
    padding: 3px 10px;
    margin-top: 10px;
    text-transform: uppercase;
    cursor: pointer;
    transition-duration: 1000ms;
}

.changeButton:hover {
    background-color: transparent;
}

.disabledInput {
    text-decoration: line-through;
    margin-left: 20px;
    margin-right: 50px;
    opacity: 0.4;
    text-transform: uppercase;
    cursor: pointer;
}

.taskName {
    cursor: pointer;
    margin-left: 20px;
    margin-right: 50px;
    text-transform: uppercase;
    cursor: pointer;
}

.taskName:hover {
    transform: scale(1.05);
    text-decoration: underline;
    transition-duration: 1000ms;
}

        /* Filtered */

    .filteredContainer {
        display: flex;
        font-size: 24px;
        justify-content: space-between;
        align-items: center;
    }

    .filteredActive {
        background-color: aqua;
        padding: 0 10px;
        border-radius: 10px;
    }

    .spn {
        margin: 0 0 0 50px;
        border: 1px solid aqua;
        width: 200px;
        background-color: transparent;
        border-color: aqua;
        padding: 0 10px;
        border-radius: 10px;
        cursor: pointer;
        transition-duration: 1000ms;
    }
    
    .spn:hover {
        background-color: aqua;
        border-radius: 10px;
    }


@media only screen and (max-width: 768px) {
    .btn {
        width: 170px;
        padding: 0 10px;
        border-radius: 10px;
    }

        /* InputArea */ 
    .InputArea {
        width: 200px;
    }

        /* TaskList */ 
    .taskListContainer {
        margin: 20px 5px;
        font-size: 20px;
    }

        /* TaskChange */
    .changeContainer {
        margin: -13px 0 0 10px;
    }

    .chaneInput {
        font-size: 16px;
    }

    .changeButton {
        font-size: 16px;
        padding: 3px 10px;
        margin-top: 10px;
        text-transform: uppercase;
        cursor: pointer;
        transition-duration: 1000ms;
    }

    .disabledInput {
        margin-left: 10px;
        margin-right: 50px;
        font-size: 20px;
    }
    
    .taskName {
        font-size: 20px;
        margin-left: 10px;
        margin-right: 50px;
    }

        /* Filtered */
    
    .filteredContainer {
        font-size: 20px;
    }

    .spn {
        margin: 0 5px 0 10px;
    }

}
